.news-page {
    background-color: #f5f5f5;
    width: 100%;
    height: 100%;
    position: relative;
    align-items: center;
    /* border: solid red; */
    overflow: scroll;
    padding-bottom: 1%;
}


.story-title {
    text-align: center;
    padding: 1%;
}

.news-card {
    /* border: solid blue; */
    background-color: white;
    width: 60%;
    height: min-content;
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-top: 1rem;
    box-shadow: 1px 1px 1px 1px gray;
    border-radius: 12px;
}

.news-card:hover {
    color: #010080;
}

.news-card-image-container {
    border-radius: 12px 0px 0px 12px;
    /* border-bottom: solid brown; */
    height: min-content;
}

.news-card-image {
    /* border-bottom: solid gold; */
    border-radius: 12px 0px 0px 12px;
}

.news-text {
    text-align: center;
    width: 100%;
    /* border: solid red; */
    height: fit-content;
}

.news-card-desc {
    overflow: hidden;
    /* white-space: nowrap; */
    /* Don't forget this one */
}

.news-card-desc-text {
    height: 2.25em;
    overflow: hidden;
    text-overflow: ellipsis;
}