.subRow
{
    background-color: #fff;
}

.subRow:nth-child(even)
{
    background-color: #ccc;
}

.subCell{
    border: black;
}

.MySubContainer{
    width: 80%;
    margin-left: 10%;
    background-color: white;
    text-align: center;
}

.sub-table{
    /* border: solid black;
    width: 100%; */
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.sub-table thead tr {
    background-color: #010080;
    color: #ffffff;
}

.sub-table td,th {
    padding: 12px 15px;
}


.sub-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.sub-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.sub-table tbody tr:last-of-type {
    border-bottom: 2px solid black;
}

.sub-table tbody tr.active-row {
    font-weight: bold;
    color: #010080;
}