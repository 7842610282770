.ArticlesView-container{
	background-color: #f5f5f5;
	width: 100%;
	height: 100%;
	position: relative;
	align-items: center;
}

.cards-row{
	position: relative;
	/* border:solid; */
	margin: auto;
	height: 100%;
	width: 60%;
	align-items: center;
	display: flex;
	flex-direction: column;
	
}

.card{
	background-color: white;
	display: flex;
	flex-direction: row;
	height: max-content;
	width: 100%;
	border-radius: 12px;
	margin-top: 1rem;
	box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.397);
}

.card:hover{
	color: #010080;
	box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.397);
}

.card-title{
	text-align: center;
    padding: 1%;

}

.card-description{
	position: relative;
}

.card-image-container{
	height: auto;
	width: 10rem;
}

.card-image{
	height: auto;
	width: 10rem;
	border-radius: 12px 0px 0px 12px;
	/* border: solid */
	/* border-right: solid lightgray; */
}

.card-actions{
	/* border: solid; */
	height: max-content;
	width: max-content;
	position:relative;
	margin-left: 20%;
	margin-top: 10%;
}

.news-text{
    /* border: solid red; */
    height: fit-content;
}

.card-info{
    text-align: center; 
    width: 100%;
    /* border: solid; */
}

.exclusive-card-div{
	float: right;
	padding: 0 10px 0 0;
}