.signin-wrapper{
    display: flex;
}

.form-container{
    position: relative;
    margin: auto;
    height: max-content;
    width: max-content;
    padding: 20px 50px 20px 50px;
    background-color: white;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.397);
    border: none;
    border-radius: 12px;
    display: flex;
    right: 0;
    left: 0;
}

.buttonsSignin{
    position:inherit;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.title{
   font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   font-size:x-large;
   color: black;
}

.inputInfo{
    border: none;
    align-items: center;
}

.email-input, .password-input{
    position: inherit;
    display: flex;
    width: 50ex;
    height: 40px;
    border-radius: 12px;
    border: solid lightgray;
    box-shadow: none;
    margin-top: 1rem;
    background-color: rgb(245, 245, 245);
    padding: 0.5rem 1rem;
}

.login-button, .signup-button{
   position: inherit;
   padding: 1rem 2rem;
   width: 50%;
   align-self: center;
   border-radius: 12px;
   border: none;
   box-shadow: none;
   margin: 5px 5px 5px 5px;
   /* border-color: rgb(215, 88, 190); */
   background-color: #010080;
   color: white;
}

.signup-button{
    color: #f74d00;
    background-color: white;
    font-weight: 300;
    font-size: 11pt;
    border: solid 1px lightgray;
    /* padding: 0rem .5rem; */
}

.login-button{
    width: 100%;
    margin-top: 1rem;
}

.login-button:hover{
   background-color: #f74d00;
   color: white;
}

.signup-button:hover{
    /* color: #f74d00; */
    background-color: #f5f5f5;
}

.forgot-password{
    position: inherit;
    text-decoration: none;
    width: max-content;
    color: blue;
    margin: .5rem auto;
    font-weight: 400;
    font-size: 9pt;
}

.forgot-password:active{
    color: purple;
}

.separator{
    height: 1px;
    width: inherit;
    background-color: lightgray;
    margin: 1rem;
}

.picture{
    border-radius: 100%;
    width: 500px;
    height: 500px;
}

.logo-wrapper{
    position: relative;
    margin: auto;
    display: flex;
    left: 0;
}

.right-side-container{
    position: absolute;
    display: flex;
    width: 50%;
    height: 100%;
    background-color: #010080;
    right: 0;
}

.left-side-container{
    position: absolute;
    display: flex;
    width: 50%;
    height: 100%;
    background-color: lightgray;
    left: 0;
}
