.navbar{
    width: 100%;
    background-color: #010080;
    overflow: hidden;
}

.navbar a {
    float: left;
    font-size: 16px;
    text-decoration: none;
}

.dropdown {
    float: left;
    overflow: hidden;
}

.account{
    float: right;
    overflow: hidden;
}

.dropbtn {
    font-size: 16px;  
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
}


.dropdown-content button{
    background-color: inherit;
    font-family: inherit;
    font-size: 16px;
    border: none;
    outline: none;
    color: #010080;
    display: block;
    text-align: left;
    text-decoration: none;
    padding: 12px 16px;
    width: 160px;
}

.dropdown-content button:hover, .account:hover .dropbtn, .navbar .nav-link:hover, .dropdown:hover .dropbtn {
    background-color: #f74d00;
    color: white;
}

.home-button{
    display: flex;
    justify-content: center;
    text-align: center;
    padding:16px;
}

.home-nameplate{
    color: white;
    font: "Segoe UI Black";
}

.logo{
    background-color: white;
    padding:0 1% 0 1%;
    float: left;
    height: 3rem;
    width: 3rem;
}

.account .dropdown-content {
    display: none;
    position: absolute;
    background-color: #010080;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: 0;
}

.account .dropdown-content a {
    float: none;
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #010080;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 100;
}

.dropdown-content a {
    float: none;
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {
    color: white;
    background-color:#f74d00 ;
}

.account:hover .dropdown-content, .dropdown:hover .dropdown-content {
    display: block;
}

