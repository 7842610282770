/* For all pages */
#article-container{
  /* height: max-content;
    width: max-content;
    margin:auto;
    overflow: hidden;
    flex: auto; */
  background-color: white;
  position: relative;
  text-align: center !important;
  margin: auto;
  height: max-content;
  width: 80% !important;
}



.article-content-wrapper{
  background-color: white;
  position: relative;
  width: 70%;
  margin: auto;
}



.title{
  text-align: center !important;
}

.subtitle{
  text-align: center !important;

}

.article-wrapper {
  position: absolute;
  height: max-content;
  background-color: #f5f5f5;
  /* padding-left: 15px;
  padding-right: 15px; */
  /* position:relative;
    border: 0;
    background-color: #f5f5f5; */
}

.article-image-container {
  width: max-content;
  display: flex;
  justify-content: center;
  margin-left:auto;
  margin-right:auto;
}

.article-image {
  display: block;
  width: 500px;
  height: auto;
  
}