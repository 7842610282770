.allGames-container{
    display: flex;
    flex-direction: row;
}
.allGames{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
}   

.allGames::-webkit-scrollbar {
    display: none;
  }
.gameBox{
    display: flex;
    flex-direction: column;
    border: solid black;
    padding: 1rem;
    font-weight: bold;
    background-color: white;
    width: max-content;
}
.gameBox-time{
    width: max-content;
    color: green;
}
.selected{
    border: solid green;
    border-width: .25rem;
}