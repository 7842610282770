.story-container{
    background-color: #f5f5f5;
    position: relative;
    width: 100%;
    height: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.story-content{
    padding: 5%;
    background-color: white;
    margin: auto;
    margin-top: 2%;
    height: max-content;
    width: 50%;
    border-radius: 12px;
    border: solid gray;
}

.story-img-container{
    text-align: center;
    position: relative;
    /* border: solid orange; */
    margin: auto;
    width: 80%;
}

.preview-img{
    /* border: dashed red; */
    width: 100%;
}

.story-header{
    text-align: center;
    margin: auto;
    padding: 1% 0 1% 0;
    width: 80%;
}

.story-byline{
    color: gray;
    text-align: left;
}

.written-date{
    float: right;
}

.description-container{
    width: 80%;
    height: max-content;
    /* border: solid blue; */
    margin: auto;
}

.description{
    position: inherit;
    font-family: inherit;
    white-space: pre-wrap;
}