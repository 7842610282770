.importProjectionsContainer{
    
    display: flex;
    touch-action: none;
    width: 95vw;
    height: 75vh;
    left: 2.5%;
    margin: 1rem auto;
    overflow: auto;
    border: solid black;
    padding: 1%;
    border-radius: 12px;
    position: fixed;
    background-color: white;
    z-index: 1000000000000;
}

.importProjections{

}

.players td{
    padding: 12px;
}

.players{
    table-layout: fixed;

}

.playerSearchTd{
    overflow: hidden;
}

.leftImportPlayer{
    border-left: solid black;
}

.playerProjections{
    overflow: scroll;
    height: 50vh;
}

.columnMatching{
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    justify-content: space-between;
}

.columnOption{
    display: flex;
    flex-direction: row;
}

.playerSearchWrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.playerSearch{
    width: 60%;
    z-index: 10000;
}
