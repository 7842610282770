.title-pricing{
    /* border: solid; */
    display: flex;
    flex-direction: row;
    padding: 5%;
    align-items: center;
}

.logo-pricing{
    /* border: solid; */
    max-width: 10%;
}

.title-text{
    font-size: xxx-large;
    font-family: Copperplate;
    height: max-content;
    /* border: solid; */
}

.pricing-listings{
    width: 80%;
    position: absolute;
    margin-left: 10%;
    background-color: white;
}

.pricing-package-div{
    /* border: solid; */
    position: relative;
    display:grid;
    grid-template-columns: 1fr 1fr;
    width: 80%;
    height: 50%;
    margin: auto;
    justify-content: center;
    text-align: center;
    margin-bottom: 15%;
}

.pricing-button{
    position: relative;
    margin-top: 1%;
}

.pricing-option{
    margin: auto;
    /* max-width: 50%; */
}

.content-container{
    border: solid #010080;
    border-radius: 12px;
    padding: 10%;
    margin: 5%;
}

.plan-name{
    /* border: solid; */
    margin: auto;
    width: fit-content;
    margin-bottom: 2%;
}

.plan-price{
    /* border: solid; */
    margin: auto;
    width: fit-content;
    font-size:5rem;
    color: #f74d00;
    margin-bottom: 2%;
}

.plan-description{
    /* border: solid; */
    color: darkslategray;
    margin: auto;
    width: 100%;
}

.pricing-button{
    margin-top: 10%;
}

.subscribe-button{
    text-decoration: none;
    width: 50%;
    border-radius: 12px;
    padding: 5%;
    border: none;
    box-shadow: none;
    background-color: #010080;
    color: white;
}

.reduce-continue-pricing{
    max-width: 50%;
}

.continue-button-container{
    border: solid green;
    display: flex;
    align-items: center;
    justify-content: center;
}

