.eyeball{
    height: 1.2rem;
    width: 1.2rem;
    opacity: .5;
    position: absolute;
    /* border: solid 1px red; */
}

.eyeball-see{
    height: 1.2rem;
    width: 1.2rem;
    opacity: 1;
    position: absolute;
    /* border: solid 1px red; */
}

.eye-container{
    position:fixed;
    height: max-content;
    width: max-content;
    /* border: dashed blue; */
    padding-top: 2.2rem;
    padding-left: 22rem;
}

.password-container{
    height: max-content;
    width: max-content;
    /* border: solid red; */
    display:flex;
}

.close-window{
    padding: .5rem;
    border-radius: 12px;
    background-color: rgb(255, 237, 240);
    border: none;
}

.close-window:hover{
    background-color: rgb(234, 127, 127);
}

.requirements-message{
    padding: 1%;
    border: solid rgb(248, 168, 181);
    border-radius: 12px;
    background-color: pink;
    width: max-content;
    height: max-content;
    z-index: 2;
    margin: auto;
    top: 50%;
    -ms-transform: translateY(50%);
    transform: translateY(50%);
}