body {
    height: 100%;
  }
  
  .container-dnd {
    display: flex;
    touch-action: none;
    width: 600px;
    margin: 1rem auto;
    padding-right: 3%;
  }
  .container-dnd-container {
    
    display: flex;
    touch-action: none;
    width: 600px;
    margin: 1rem auto;
    overflow: auto;
  }
  
  
  .corkboard {
    height: 300px;
    width: 300px;
    /* border: 2px solid #000; */
  }

  .file-file{
    width: 50px;
  }

  .corkboard-players {
    height: 43px;
    width: 200px;
    /* border: 2px solid #000; */
    background: #fff;
  }
/*   
  .file-item {
    padding: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  } */
  
  .file-content {
    width: max-content;
    height: auto;
    box-sizing: border-box;
    background: #fff;
    border-color: #000;
    border-width: 2px;
    border-style: solid;
    user-select: none;
    display: flex;
    justify-content: center;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
    padding: .5rem;
  }
  

    .grid-item{
        width: 50px;
        height: 50px !important;
    }

    .grid-item-player{
        height: 25px;
    }

    .dnd-dropzones{
        height: 43px;
        display: flex;
        padding: 10px;
    }

    .dnd-dropzones-items{
        height: 43px;
        flex-direction: row;
    }

    .confirm-popup-button{
      position: inherit;
      padding: 1rem 2rem;
      width: 50%;
      align-self: center;
      border-radius: 12px;
      border: none;
      box-shadow: none;
      /* border-color: rgb(215, 88, 190); */
      background-color: #010080;
      color: white;
   }

   .confirm-popup-button:hover{
    background-color: #f74d00;
    color: white;
 }
    
