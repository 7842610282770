.forgot-password-container{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    background-color: #f5f5f5;
    /* border: dashed red; */
}

.reset-text{
    display: flex;
    flex-direction: column;
    text-align: left;
}

.email-forgot{
    position: inherit;
    width: 50ex;
    height: 40px;
    border-radius: 12px;
    border: solid lightgray;
    box-shadow: none;
    background-color: rgb(245, 245, 245);
}

.submit-forgot{
    position: relative;
    background-color: rgb(207, 30, 173);
    color: white;
    border: none;
    font-size: medium;
    padding: 1rem 3rem;
    border-radius: 12px;
    margin: 1rem;
}

