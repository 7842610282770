.optomizer-wrapper {
    display: flex;
}

.close-button {
    font-size: x-large;
    float: right;
    padding: .5rem;
    border-radius: 12px;
    background-color: lightgray;
    border: none;
}

.close-button:hover {
    color: red;
}

#opto {
    position: relative;
    /* border: solid; */
    /* right: 0; */
    width: 80%;
    justify-content: center;
    margin: auto;
}

.dfs-table th,
td {
    border-bottom: 1px solid #ddd;
}


.dfs-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.dfs-table th {
    background-color: #010080;
    color: white;
}

.table-div {
    box-shadow: 3px 3px 10px 3px grey;
    border-bottom: none;
    border-radius: 12px 12px 0 0;
    overflow: auto;
    /* height: 600px; */
    width: 100%;
    margin: auto;
}

.table-div thead th {
    position: sticky;
    top: 0;
    padding: .5rem;
    z-index: 1;
}

.dfs-table {

    border-collapse: collapse;
    width: 100%;
}

.proj-cell {
    padding: .5rem 0 .5rem .5rem;
    text-align: center;
    white-space: nowrap;
}

.proj-submit {
    height: max-content;
    width: max-content;
    padding: 1rem;
    border: none;
    background-color: #010080;
    color: white;
    border-radius: 12px;
}

.selection-div {
    /* border: 1px solid black; */
    margin: 1rem;
    padding: 2rem 2rem;
    text-align: center;
}

.dropdown-selection {
    display: inline-block;
    /* border: 1px solid red; */
    padding: 1rem 1rem;
    vertical-align: middle;
    width: 40%;
}

.basic-settings {
    display: flex;
    margin: 1rem 0;
}

.numeric-input-div {
    margin: 1rem 1rem;
}

.basic-settings .numeric-inputs-basic {
    display: flex;
    margin: 0 auto;
}

.num-input {
    border: solid lightgray;
    padding: .5rem;
    border-radius: 12px;
}

.settings-optomizer {
    background-color: white;
    text-align: center;
    height: max-content;
}

.team-cap-table {
    background-color: white;
}

.secondary-button,
.apply-settings {
    margin: .5rem;
    padding: 1rem;
    background-color: #f47d00;
    color: white;
    border: none;
    border-radius: 12px;
}

.secondary-button:hover {
    background-color: #f7962f;
}

.apply-settings {
    width: 50%;
    margin: auto;
}

.optomize-div {
    display: flex;
    /* border: solid red 5px; */
    flex-direction: row;
    /* border-radius: 12px; */
    padding: 1rem;
    margin: 1rem;
    text-align: center;
    z-index: 99;
}

.collapse-trigger {
    text-align: left;
    width: 100%;
    border: solid lightgray 1px;
    background-color: inherit;
    border-radius: 12px;
    padding: 1rem;
}

.collapse-trigger:hover {
    background-color: lightgray;
    cursor: pointer;
}

.collapse-content {
    display: flex;
    background-color: lightgray;
    border-radius: 12px;
}

.collapse-content>div {
    padding: 2rem;
    margin: 0 auto;
    border: solid gray 1px;
    border-radius: 12px;
}


.optomize-button {
    background-color: #010080;
    margin: 1rem;
    padding: 1rem;
    color: white;
    border: none;
    border-radius: 12px;
}

.optomize-button:hover {
    background-color: #1d1de6;
}

.projection-button-divs {
    text-align: center;
}

.projections-button-left,
.projections-button-right {
    padding: 1rem;
    background-color: #010080;
    color: white;
    border: none;
}

.projections-button-left:hover,
.projections-button-right:hover {
    background-color: #1d1de6;
}

.projections-button-left:active,
.projections-button-right:active {
    background-color: #f47d00;
}

.projections-button-left {
    border-right: solid white;
    border-radius: 12px 0 0 12px;
}

.projections-button-right {
    border-radius: 0 12px 12px 0;
}

.csv-downloader {
    border: none;
    background-color: #010080;
    color: white;
    border-radius: 12px;
    padding: 1rem;
    margin: 1%;
}

.csv-downloader:hover {
    background-color: #1d1de6;
}

.sort-buttons {
    margin-bottom: 8px;
}

.sort-button-left,
.sort-button-right,
.sort-button-inner {
    padding: 1rem;
    color: white;
    border: none;
    background-color: #010080;
}

.sort-button-left:active,
.sort-button-right:active,
.sort-button-inner:active {
    background-color: #f47d00;
}

.sort-button-left:hover,
.sort-button-right:hover,
.sort-button-inner:hover {
    background-color: #1d1de6;
}

.sort-button-inner,
.sort-button-right {
    border-left: solid white;
}

.sort-button-left {
    border-radius: 12px 0 0 12px;
}

.sort-button-right {
    border-radius: 0 12px 12px 0;
}

.dfs-pricing {
    text-decoration: none;
    color: white;
}

.hidden-projections {
    width: 100%;
    padding: 1%;
    text-align: center;
    margin: auto;
    justify-content: center;
}

.dfs-projections-button {
    text-align: center;
    margin: auto;
    justify-content: center;
    width: 50%;
}



.ss-listings {
    width: 80%;
    height: 100%;
    position: absolute;
    margin-left: 10%;
}

.ss-div {
    position: relative;
    width: 80%;
    height: 50%;
    margin: auto;
    justify-content: center;
    text-align: center;
    margin-bottom: 15%;
}



.ss-image-div {
    width: 100%;
}

.ss-image {
    max-height: 100%;
    max-width: 100%;
}

.headshot-td {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.headshot-td-img {
    height: 50px;
    width: auto;
}

.advanced-settings {
    margin-bottom: 1rem;
}

.player-count-team {
    /* padding: 1rem; */
    /* border: solid green; */
}

.player-count-team-image-container {
    height: max-content;
    width: auto;
    border-radius: 2px 2px 2px 2px;
}

.player-count-team-image {
    /* height: inherit; */
    width: auto;
}

.player-count-team-num {
    border: solid red;
}

.player-count-team-num-div {
    height: max-content;
    width: max-content;
}

.player-count-team-num-input {
    width: inherit;
    border: solid lightgray;
    border-radius: 12px;
    padding: .5rem;
}

.stacking {
    background-color: white;
    width: max-content;
    height: max-content;
}

.stacking-table {
    text-align: center;
    font-family: inherit;
    background-color: white;
}

.stacking-cell {
    padding: 1rem;
}

.import-Popup {
    z-index: 100;
    position: fixed;
    background-color: lightgray;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: 3px 3px 10px 3px grey;
    width: 75%;
    left: 12.5%;
    top: 10%;
    max-height: 75%;
    padding: 0 0 5% 0;
}

.import-Popup-container {
    overflow: hidden;
    z-index: 100;
    width: 100vw;
}

.cancel {
    width: 50%;
    background-color: #f47d00;
}

.cancel:hover {
    background-color: #f74d00;
}

.num-input-table {
    color: black;
    padding: .5rem;
    border-radius: 12px;
    border: none;
    width: 50%;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}


.exposureFilter-container {
    display: flex;
    flex-direction: row;

}

.exposureFilter {
    width: max-content;
    height: max-content;
    background-color: #f47d00;
    color: white;
    border-radius: 12px;
    padding: .5rem;
    margin: 0 1rem .3rem 1rem;
    display: flex;
    flex-direction: row;
}


.exposureFilter-cancel {
    padding-left: .5rem;
    padding-right: .5rem;
    color: slategrey;
}

button:hover {
    cursor: pointer;
}

input[type="button"]:hover {
    cursor: pointer;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.slider-input {
    display: flex;
    justify-content: center;
    align-items: center;
}