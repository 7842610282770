.homeContainer{
    text-align: center;
    background-color: #f5f5f5;
    /* height: 100%; */
    width: 100%;
    position: relative;
    padding: 0 0 10% 0;
}

.banner-container{
    width: 100%;
    height: max-content;
    /* border: solid red; */
}

.discord-link{
    color: blue;
    text-decoration: none;
}

.stories-container-home{
    /* border: solid blue; */
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.mini-news{
    width: 50%;
    /* border: solid red; */
    margin-bottom: 1%;
}

.mini-articles{
    width: 50%;
    margin-left: -5%;
    /* border: solid red; */
}

.mini-news-display{
    /* width: 50%; */
    /* border: solid black; */
    position: relative;
    margin-bottom: .5rem;
}

.mini-articles-display{
    position: relative;
    margin-bottom: .5rem;
}

.all-news-div{
    position: relative;
    margin-top: 5%;
}

.all-news{
    border-radius: 12px;
    text-decoration: none;
    font-family: inherit;
    padding: 1rem;
    color: white;
    background-color: #010080;
    position: relative;
    margin: auto;
}

.all-news:hover{
    background-color: #f74d00;
}